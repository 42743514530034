<script setup lang="ts">
import LogoGoogle from '~/assets/images/logo-google.svg';
import LogoFacebook from '~/assets/images/logo-facebook.svg';

useHead({ script: [{ src: 'https://accounts.google.com/gsi/client', async: true }] });

const auth = useAuth();
const oauth = useOAuth();

const props = defineProps<{ title: string; context: 'login' | 'register' }>();
const loading = defineModel<boolean>('loading', { default: false });

function handleOauth(provider: 'google' | 'fb') {
  loading.value = true;
  oauth.requestGoogleToken();
}

const onError = (e: Error) => ((loading.value = false), console.error(e));
onBeforeMount(() => oauth.init(props.context, { onError }));
</script>

<template>
  <div v-if="!auth.isWIFI && (oauth.google || oauth.fb)" class="py-4 mb-md-16" style="align-self: center">
    <div class="text-subtitle-2 text-center mb-3">{{ $props.title }}</div>
    <div class="d-flex justify-center ga-2">
      <v-btn :loading v-if="oauth.google" color="#d6d9de" variant="outlined" @click="handleOauth('google')">
        <img :src="LogoGoogle" alt="Google Login" />
      </v-btn>
      <v-btn v-if="oauth.fb" :loading color="#1877f2" variant="outlined">
        <img :src="LogoFacebook" alt="FB Login" />
      </v-btn>
    </div>
  </div>
</template>
